/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { CardHeader, Col, Card, CardBody } from 'reactstrap';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlus, faCommentDots } from '@fortawesome/free-solid-svg-icons';

// DataTable
import DataTable from 'react-data-table-component';

// Select
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

// Mixins
import { CardBodyCustom, Heading, Title, EmptyState } from '../mixins';

import { connect } from 'react-redux';
import CanRBac, { checkDataPermission } from '../../../components/CanRBac';

import PartialDeliveryRow from './PartialDeliveryRow';
import { findProduct } from '..';

const DetailForm = ({
  products,
  packedTypes,
  productList,
  handleProductChange,
  handleChange,
  isEditable,
  deleteProductRow,
  addProductRow,
  addPartialDeliveryRow,
  removePartialDeliveryRow,
  handlePartialDeliveryChange,
  currentUser,
  currency,
  handleKeyDown,
  addObservation,
}) => {
  const [orderAvgPercent, setOrderAvgPercent] = useState(0);
  const [fullProductList, setFullProductList] = useState(productList);
  const productsPercentArray = [];

  useEffect(() => {
    if (Array.isArray(productList)) {
      const updatedProductList = productList.map((product) => {
        const baseProduct = findProduct(products.data, product.product._id || product.product);
        if (!baseProduct) {
          console.warn(`No se encontró el producto con ID: ${product._id}`);
          return product;
        }

        return {
          ...product,
          minPrice: baseProduct.minPrice || null,
          minPriceDollars: baseProduct.minPriceDollars || null,
        };
      });

      setFullProductList(updatedProductList);
    } else {
      console.error('productList no es un array válido');
    }
  }, [productList, products]);

  useEffect(() => {
    setOrderAvgPercent(Math.round(productsPercentArray.reduce((a, b) => a + b, 0) / productsPercentArray.length));
  }, [productsPercentArray]);

  useEffect(() => {
    handleChange('deliveryPercent', orderAvgPercent);
  }, [orderAvgPercent]);

  const sterilizedOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 9, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMaskOptions = {
    decimalSymbol: ',',
    prefix: currency !== 'dollars' ? '$' : 'U$D',
  };

  function getTotalOrder(currency) {
    const totalRowPrice = [];
    productList.forEach((row) => totalRowPrice.push(row.price * row.quantity).toFixed(currency === 'dollars' ? 4 : 2));
    const price = totalRowPrice.reduce((a, b) => a + b, 0);

    if (currency === 'dollars') {
      // Format as dollars with a comma for thousands and four decimal places for cents
      const parts = price.toFixed(4).toString().split('.');
      const dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const cents = parts[1] || '0000'; // Handle missing cents, default to '0000' for four decimal places
      return '$' + dollars + '.' + cents;
    } else if (currency === 'pesos') {
      // Format as Argentine pesos with two decimal places
      return '$' + price.toFixed(2).replace(/\d(?=(\d{3})+\,)/g, '$&.');
    } else {
      // Handle other currencies or unsupported currencies
      return 'Unsupported currency';
    }
  }

  const calculatetotalRowPrice = (index, currency) => {
    const totalRowPrice = [];
    productList.forEach((row) => totalRowPrice.push(row.price * row.quantity).toFixed(currency === 'dollars' ? 4 : 2));
    return totalRowPrice[index];
  };

  const getDeliveredTotalQuantity = (index) => {
    if (productList[index] !== undefined) {
      const qtyArray = [];
      productList[index].partialDelivery.forEach((row) =>
        row.quantity ? qtyArray.push(parseInt(row.quantity, 10)) : null,
      );
      const sum = qtyArray.reduce((a, b) => a + b, 0);
      return sum;
    }
    return null;
  };

  const productsData = products?.data?.map((product) => ({
    label: product.code ? `${product.code} - ${product.name}` : `${product.name}`,
    value: {
      _id: product._id,
      name: product.name,
    },
  }));

  const packedTypesData = packedTypes?.data?.map((packed) => ({
    label: packed.name,
    value: packed.name,

    packed: {
      _id: packed._id,
      name: packed.name,
    },
  }));

  const getRowDeliveryPercent = (index) => {
    if (productList[index] !== undefined) {
      const deliveredQty = getDeliveredTotalQuantity(index);
      if (productList[index].quantity !== '' && productList[index].quantity) {
        const result = Math.round((deliveredQty * 100) / productList[index].quantity);
        productsPercentArray[index] = result;
        return result;
      }
      return 0;
    }
    return null;
  };

  // Datatable
  const columns = [
    {
      name: '#',
      sortable: false,
      center: false,
      minWidth: '3%',
      maxWidth: '3%',
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: '%',
      sortable: false,
      center: false,
      minWidth: '4%',
      maxWidth: '4%',
      cell: (row, index) => <p>{getRowDeliveryPercent(index)}</p>,
    },
    {
      name: 'Articulo',
      sortable: false,
      center: false,
      allowOverflow: true,
      minWidth: '30%',
      maxWidth: '30%',
      cell: (row, index) => {
        return (
          <span
            className="basic-multi-select w-100"
            data-toggle="tooltip"
            data-placement="top"
            // eslint-disable-next-line no-nested-ternary
            title={productList[index] ? (productList[index].product ? productList[index].product.name : '') : ''}
          >
            <Select
              menuPortalTarget={document.body}
              menuPosition="fixed"
              // className="font-weight-bold basic-multi-select"
              className="basic-multi-select w-100"
              placeholder="Elija un artículo"
              defaultValue={{
                // eslint-disable-next-line no-nested-ternary
                label: productList[index]
                  ? productList[index].product
                    ? `${productList[index].product.code} - ${productList[index].product.name}`
                    : ''
                  : '',
              }}
              onChange={(options) => handleProductChange(index, 'product', options.value._id)}
              options={productsData}
              isDisabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
            />
          </span>
        );
      },
    },
    {
      name: 'Envasado',
      sortable: false,
      center: false,
      minWidth: '20%',
      maxWidth: '20%',
      cell: (row, index) => (
        <Select
          className="w-100"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          placeholder="Elija el envasado"
          defaultValue={
            productList[index]
              ? productList[index]?.packed?.map((p) => {
                  return { label: p.name, value: p._id };
                })
              : ''
          }
          onChange={(options) => handleProductChange(index, 'packed', options)}
          isMulti
          options={packedTypesData}
          isDisabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
        />
      ),
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Estéril" className="rdt_TableCol">
          Estéril
        </p>
      ),
      sortable: false,
      center: false,
      grow: 3,
      cell: (row, index) => (
        <Select
          menuPortalTarget={document.body}
          menuPosition="fixed"
          onChange={(options) => handleProductChange(index, 'sterilized', options.value)}
          className="basic-multi-select w-100"
          // eslint-disable-next-line no-nested-ternary
          defaultValue={{ label: row.sterilized === true ? 'Sí' : row.sterilized === false ? 'No' : '' }}
          options={sterilizedOptions}
          placeholder=""
          isDisabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
        />
      ),
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Cantidad pedida" className="rdt_TableCol">
          Cantidad pedida
        </p>
      ),
      sortable: false,
      center: false,
      grow: 1,
      cell: (row, index) => (
        <MaskedInput
          mask={createNumberMask({ ...defaultMaskOptions })}
          className="form-control dmx-input w-100"
          name="quantity"
          defaultValue={row.quantity}
          onChange={(e) => handleProductChange(index, e.target.name, e.target.value)}
          disabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
        />
      ),
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Cantidad entregada" className="rdt_TableCol">
          Cantidad entregada
        </p>
      ),
      sortable: false,
      center: false,
      grow: 1,
      cell: (row, index) => {
        const totalQuantity = getDeliveredTotalQuantity(index);
        return (
          <p
            style={{ backgroundColor: '#E9ECEF', cursor: 'default' }}
            disabled
            className="form-control dmx-input w-100"
          >
            {totalQuantity != null ? totalQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'Sin valor'}
          </p>
        );
      },
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Cantidad restante" className="rdt_TableCol">
          Cantidad restante
        </p>
      ),
      sortable: false,
      center: false,
      grow: 1,
      cell: (row, index) => (
        <p style={{ backgroundColor: '#E9ECEF', cursor: 'default' }} disabled className="form-control dmx-input w-100">
          {(row.quantity - getDeliveredTotalQuantity(index)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        </p>
      ),
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Precio unitario" className="rdt_TableCol">
          Precio unitario
        </p>
      ),
      sortable: false,
      center: false,
      minWidth: '120px',
      cell: (row, index) => (
        <CanRBac
          role={currentUser?.userType}
          perform="np:edit:product:minPrice"
          yes={() => (
            <MaskedInput
              mask={createNumberMask({
                ...defaultMaskOptions,
                ...currencyMaskOptions,
                decimalLimit: 4,
              })}
              className="form-control dmx-input w-100"
              name="price"
              defaultValue={row.price && row.price.toString().replace(/[.]/g, ',')}
              placeholder={
                row && row.minPrice && row.minPriceDollars
                  ? `${currency === 'dollars' ? `U$D ${row.minPriceDollars}` : `$${row.minPrice}`}`
                  : ''
              }
              onChange={(e) =>
                handleProductChange(
                  index,
                  e.target.name,
                  e.target.value
                    .replace(/(U\$D)/g, '')
                    .replace(/[$.]/g, '')
                    .replace(/[,]/g, '.'),
                  true,
                )
              }
              disabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          )}
          no={() => (
            <MaskedInput
              mask={createNumberMask({
                ...defaultMaskOptions,
                ...currencyMaskOptions,
                decimalLimit: 4,
              })}
              className="form-control dmx-input w-100"
              name="price"
              placeholder={
                row && row.minPrice && row.minPriceDollars
                  ? `${currency === 'dollars' ? `U$D ${row.minPriceDollars}` : `$${row.minPrice}`}`
                  : ''
              }
              defaultValue={row.price && row.price.toString().replace(/[.]/g, ',')}
              onChange={(e) =>
                handleProductChange(
                  index,
                  e.target.name,
                  e.target.value
                    .replace(/(U\$D)/g, '')
                    .replace(/[$.]/g, '')
                    .replace(/[,]/g, '.'),
                  false,
                )
              }
              disabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          )}
        />
      ),
    },
    {
      name: (
        <p data-toggle="tooltip" data-placement="top" title="Precio total" className="rdt_TableCol">
          Precio Total
        </p>
      ),
      sortable: false,
      center: false,
      minWidth: '120px',
      cell: (row, index) => {
        const value = `${currency !== 'dollars' ? '$' : 'U$D'} ${calculatetotalRowPrice(index, currency)}`;

        return <input value={value} className="form-control dmx-input w-100" disabled />;
      },
    },
    {
      // name: ''  ddd,
      sortable: false,
      center: true,
      grow: 3,
      cell: (row, index) => (
        <>
          <CanRBac
            role={currentUser?.userType}
            perform={isEditable ? 'np:create:partial_delivery' : ''}
            yes={() => (
              <div className="text-success p-1 dmx-action-button">
                <FontAwesomeIcon
                  title="Agregar entrega parcial al producto"
                  aria-hidden="true"
                  icon={faPlus}
                  onClick={() => addPartialDeliveryRow(index)}
                />
              </div>
            )}
          />

          <CanRBac
            role={currentUser?.userType}
            perform={isEditable ? 'np:delete:product' : ''}
            yes={() => (
              <div className="text-danger p-1 dmx-action-button">
                <FontAwesomeIcon
                  title="Quitar producto de la lista"
                  aria-hidden="true"
                  icon={faTimesCircle}
                  onClick={() => deleteProductRow(index)}
                />
              </div>
            )}
          />

          <CanRBac
            role={currentUser?.userType}
            perform={isEditable ? 'np:vis:product:observations' : 'np:edit:product:observations'}
            yes={() => (
              <div className="text-obs p-1 dmx-action-button">
                <FontAwesomeIcon
                  title="Agregar una observacion"
                  aria-hidden="true"
                  icon={faCommentDots}
                  onClick={() => addObservation(index)}
                />
              </div>
            )}
          />
        </>
      ),
    },
  ];

  const customStyles = {
    // rows: {
    //   style: {
    //     minHeight: '72px', // override the row height
    //   },
    // },
    headCells: {
      style: {
        paddingLeft: '4px', // override the cell padding for head cells
        paddingRight: '4px',
      },
    },
    cells: {
      style: {
        paddingLeft: '4px', // override the cell padding for data cells
        paddingRight: '4px',
      },
    },
  };

  return (
    <Col xl>
      <Card>
        <Heading>
          <Title>DETALLES</Title>
        </Heading>

        <CardBodyCustom>
          <div className="data-table-container">
            <DataTable
              customStyles={customStyles}
              columns={columns}
              data={fullProductList}
              noDataComponent={<EmptyState>No hay registros para mostrar</EmptyState>}
              expandableRowDisabled={(row) => {
                return row.partialDelivery && row.partialDelivery.length === 0;
              }}
              expandableRows
              expandableRowsComponent={
                productList && (
                  <PartialDeliveryRow
                    isEditable={
                      checkDataPermission(currentUser?.userType, 'np:edit:partial_delivery') ? isEditable : false
                    }
                    removePartialDeliveryRow={removePartialDeliveryRow}
                    handlePartialDeliveryChange={handlePartialDeliveryChange}
                  />
                )
              }
            />
          </div>

          <div className="mt-3">
            {isEditable ? (
              <button
                className="btn btn-primary"
                type="button"
                disabled={checkDataPermission(currentUser?.userType, 'np:edit:product') ? !isEditable : true}
                onClick={() => addProductRow()}
              >
                Agregar
              </button>
            ) : null}
            {getTotalOrder() != 0 && (
              <h4 style={{ float: 'right' }}>
                Total {currency !== 'dollars' ? '$: ' : 'U$D: '}
                {getTotalOrder(currency)}
              </h4>
            )}
          </div>
        </CardBodyCustom>
      </Card>
    </Col>
  );
};

const state = ({ userStore }) => {
  return {
    currentUser: userStore.information.data,
  };
};

export default connect(state)(DetailForm);
