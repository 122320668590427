/* eslint no-underscore-dangle: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-shadow: 0 */

// Third-party Imports
import React, { useEffect, useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import _ from 'underscore';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { CREATE, READ, UPDATE } from '../../constant';

// My Imports
import patchProduct from '../../services/patchProduct';
import postProduct from '../../services/postProduct';
import { ProductContext } from '../../context/ProductContext';
import Spinner from '../../components/Spinner';
import SpinnerButton from '../../components/Button';
import useProductCategoriesFetcher from '../../hooks/useProductCategoriesFetcher';

const ProductForm = (props) => {
  const { productContext, modalContext } = useContext(ProductContext);
  const productCategories = useProductCategoriesFetcher();
  const [modal, setModal] = modalContext;
  const [product, setProduct] = productContext;
  const [fieldData, setFieldData] = useState(null);
  const { data, action } = props;
  const [attributes, setAttributes] = useState();
  const { _id, stock, minPrice, minPriceDollars, name, productCategoryId } = data;
  const [categoryId, setCategoryId] = useState(data.productCategoryId._id || '');
  const [categoryLabel, setCategoryLabel] = useState(data.productCategoryId.name || '');

  const isDisabled = action === READ;

  const attributeNames = {
    fabric: 'Tela',
    type: 'Tipo',
    reinforcement: 'Refuerzo',
    grammage: 'Gramaje',
    color: 'Color',
    cuff: 'Puño',
    termination: 'Envivado',
    adjustment: 'Ajuste',
    large: 'Lago',
    sterile: 'Esteril',
    elastizedWaist: 'Cintura Elastizada',
    hood: 'Capucha',
    builtInBoot: 'Bota Incorporada',
    size: 'Talle',
    measure: 'Medida',
    fenestrated: 'Fenestrado',
    fenestratedType: 'Tipo Fenestrado',
    sewing: 'Costura',
    antiSlipSole: 'Suela Antideslizante',
    pleated: 'Plisada',
    sleeve: 'Manga',
    pocket: 'Bolsillo',
    packageType: 'Envasado',
  };

  useEffect(() => {
    setAttributes(props.attributes);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    const { _id, stock, minPrice, minPriceDollars } = values;
    const productCategoryId = categoryId;
    const productCategoryLabel = categoryLabel;
    const productCategoryId2 = { ...productCategories.data.filter((p) => p._id === categoryId)[0] };
    const updatedProduct = {
      ...fieldData,
      _id,
      stock,
      minPrice,
      minPriceDollars,
      name,
      productCategoryId,
      productCategoryLabel,
    };

    if (action === UPDATE) {
      const dataType = data.type && data.type !== 'NINGUNO' ? `${data.type} ` : '';
      const dataFabric = data.fabric && data.fabric !== 'NINGUNO' ? `${data.fabric} ` : '';
      const dataGrammage = data.grammage && data.grammage !== 'NINGUNO' ? `${data.grammage} ` : '';
      const dataCuff = data.cuff && data.cuff !== 'NINGUNO' ? `${data.cuff} ` : '';
      const dataColor = data.color && data.color !== 'NINGUNO' ? `${data.color} ` : '';
      const dataSize = data.size && data.size !== 'NINGUNO' ? `${data.size} ` : '';
      const dataDetails = data.details && data.details !== 'NINGUNO' ? `${data.details}` : '';

      const nameString = `${
        data.productCategoryId && data.productCategoryId.name ? `${data.productCategoryId.name}` : ''
      } ${updatedProduct.type ? `${updatedProduct.type} ` : dataType}${
        updatedProduct.fabric ? `${updatedProduct.fabric} ` : dataFabric
      }${updatedProduct.grammage ? `${updatedProduct.grammage} ` : dataGrammage}${
        updatedProduct.cuff ? `${updatedProduct.cuff} ` : dataCuff
      }${updatedProduct.color ? `${updatedProduct.color} ` : dataColor}${
        updatedProduct.size ? `${updatedProduct.size} ` : dataSize
      }${updatedProduct.details ? `${updatedProduct.details}` : dataDetails}`;
      updatedProduct.name = nameString.replace('NINGUNO', '').trim();

      patchProduct(updatedProduct).then((res) => {
        if (res.errors) {
          toast.error(`El producto que esta intentando modificar ya existe.`, { autoClose: 3000 });
        } else {
          const productIndex = product.findIndex((p) => p._id === updatedProduct._id);
          const newData = _.clone(product);
          newData[productIndex] = { ...res, productCategoryLabel };
          setProduct(newData);
          setModal(!modal);
          setSubmitting(false);
          toast.success('El producto ha sido actualizado.', { autoClose: 3000 });
        }
      });
    }
    if (action === CREATE) {
      try {
        delete updatedProduct._id;
        const res = await postProduct(updatedProduct);
        const newData = _.clone(product);
        const newValues = {
          ...updatedProduct,
          productCategoryId: productCategoryId2,
          _id: res._id,
          name: res.name,
          code: res.code,
        };
        newData.push(newValues);
        setProduct(newData);
        setModal(!modal);
        setSubmitting(false);
        toast.success('El producto ha sido creado.', { autoClose: 3000 });
      } catch (error) {
        if (error.msg === 'PRODUCT_ALREADY_EXISTS') {
          toast.error(`El producto que esta intentando crear ya existe.`, { autoClose: 3000 });
        }
      }
    }
  };

  const initialValues = {
    _id: _id || '',
    productCategoryId: productCategoryId._id || '604577ea710af48ac0a260e6',
    stock: stock || '',
    minPrice: minPrice || '',
    minPriceDollars: minPriceDollars || '',
  };

  if (productCategories.isLoading) {
    return <Spinner size="large" isPageLoader />;
  }

  const productCategoryOptions = productCategories.data.map((category) => {
    return {
      value: category._id,
      label: category.name,
      productCategory: {
        _id: category._id,
        name: category.name,
        productAttributesId: category.productAttributesId,
      },
    };
  });

  const jsonToArray = (json) => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax,guard-for-in,prefer-const
    for (let attribute in json) {
      const item = { name: attribute, value: json[attribute] };
      result.push(item);
    }
    return result;
  };

  const selectChange = (e) => {
    // eslint-disable-next-line prefer-const
    const newfieldData = fieldData ? { ...fieldData } : {};
    newfieldData[e.attribute] = e.value;
    setFieldData(newfieldData);
  };

  const handleOnChange = (option) => {
    const newCategoryId = option.productCategory._id;
    const newCategoryLabel = option.productCategory.name;
    const attributeList = option.productCategory.productAttributesId;
    const newAttributes = jsonToArray(attributeList)
      .filter((item) => item.name !== '_id')
      .filter((item) => item.value)
      .filter((item) => item.value.length);

    const newfieldData = {};
    newAttributes.forEach((attribute) => {
      const { name, value } = attribute;
      // eslint-disable-next-line prefer-destructuring
      newfieldData[name] = value[0];
    });

    setAttributes(newAttributes);
    setFieldData(newfieldData);
    setCategoryId(newCategoryId);
    setCategoryLabel(newCategoryLabel);
  };

  const createFormFields = (attributes, values, errors, touched, handleChange, handleBlur) => {
    const productCategory = (
      <div key={uuidv4()} className="form-group mb-2">
        <label className="col-form-label font-weight-bold">Producto</label>
        <Select
          defaultValue={productCategoryOptions.filter((option) => option.value === values.productCategoryId)}
          name="productCategoryId"
          options={productCategoryOptions}
          placeholder="Seleccione el producto"
          onChange={handleOnChange}
          isDisabled={action === UPDATE || action === READ}
        />
        {errors.productCategoryId && touched.productCategoryId && (
          <div className="input-feedback">{errors.productCategoryId}</div>
        )}
      </div>
    );

    const stock = (
      <div className="form-group mb-2">
        <label className="col-form-label">Stock</label>
        <input
          className={
            errors.stock && touched.stock
              ? 'form-control dmx-custom-height error-field'
              : 'form-control dmx-custom-height'
          }
          type="number"
          name="stock"
          step="any"
          value={values.stock}
          min="0"
          placeholder="0"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
        {errors.stock && touched.stock && <div className="input-feedback">{errors.stock}</div>}
      </div>
    );

    const minPrice = (
      <div className="form-group mb-2">
        <label className="col-form-label">Precio mínimo ($)</label>
        <input
          className={
            errors.minPrice && touched.minPrice
              ? 'form-control dmx-custom-height error-field'
              : 'form-control dmx-custom-height'
          }
          type="number"
          name="minPrice"
          step="any"
          value={values.minPrice}
          min="0"
          placeholder="0"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
        {errors.minPrice && touched.minPrice && <div className="input-feedback">{errors.minPrice}</div>}
      </div>
    );
    const minPriceDollars = (
      <div className="form-group mb-2">
        <label className="col-form-label">Precio mínimo ($USD)</label>
        <input
          className={
            errors.minPriceDollars && touched.minPriceDollars
              ? 'form-control dmx-custom-height error-field'
              : 'form-control dmx-custom-height'
          }
          type="number"
          name="minPriceDollars"
          step="any"
          value={values.minPriceDollars}
          min="0"
          placeholder="0"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
        {errors.minPriceDollars && touched.minPriceDollars && (
          <div className="input-feedback">{errors.minPriceDollars}</div>
        )}
      </div>
    );

    const formFields = [];
    formFields.push(productCategory);
    formFields.push(stock);
    formFields.push(minPrice);
    formFields.push(minPriceDollars);

    const formAttributes = attributes.map((attribute) => {
      const options = attribute.value.map((val) => ({ value: val, label: val, attribute: attribute.name }));
      return (
        <div key={uuidv4()} className="form-group mb-2">
          <label key={uuidv4()} className="text-capitalize col-form-label">
            {attributeNames[attribute.name]}
          </label>
          <Select
            isDisabled={isDisabled}
            defaultValue={options.filter((option) => option.value === data[attribute.name])}
            name={attribute.name}
            options={options}
            onChange={(option) => selectChange(option)}
          />
        </div>
      );
    });

    return formFields.concat(formAttributes);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          stock: Yup.string().required('Este campo es requerido'),
          minPrice: Yup.string().required('Este campo es requerido'),
          minPriceDollars: Yup.string().required('Este campo es requerido'),
        })}
      >
        {(props2) => {
          const { values, touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit } = props2;
          return (
            <form className="theme-form" onSubmit={handleSubmit}>
              <div className="grid-conteiner">
                {createFormFields(attributes, values, errors, touched, handleChange, handleBlur)}
              </div>

              {isDisabled ? (
                ''
              ) : (
                <div className="form-group form-row mt-3 mb-0">
                  <SpinnerButton
                    className="btn btn-primary btn-block dmx-modal-save-button"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Guardar
                  </SpinnerButton>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProductForm;
